<template>
  <div>
    <v-navigation-drawer bottom light :disable-resize-watcher="true" app v-model="toggle" class="hidden-md-and-up"
                         height="auto">
      <v-list dense nav>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.route"
            color="blue-grey darken-4"
            link
        >
          <v-list-item-content>
            <v-list-item-title class="text-uppercase text-body-1"><span class="navbar font-weight-bold blue-grey--text text--darken-4">{{ item.title }}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        color="blue-grey darken-4"
    >
      <router-link to="/" class="hidden-md-and-up">
        <v-img
            src="/img/logo-consulta-publica-responsive.svg"
            height="100"
            width="180"
            contain
        />
      </router-link>
      <router-link to="/">
        <v-img
            class="ml-4 hidden-sm-and-down"
            src="/img/logo-consulta-publica.svg"
            width="420"
            contain
        />
      </router-link>

      <v-tabs right dark  slider-color="transparent" center-active class="hidden-sm-and-down">
        <v-tab class="navbar" to="/">
          Estadísticas
        </v-tab>
        <v-tab class="navbar" to="/escrutinio">
          Escrutinio
        </v-tab>
        <v-tab class="navbar" to="/mesas">
          Lista de mesas
        </v-tab>
        <v-tab class="navbar" to="/descargas">
          Descargas
        </v-tab>
      </v-tabs>
      <v-spacer/>
      <v-app-bar-nav-icon x-large dark @click="toggle = !toggle" class="hidden-md-and-up"/>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      toggle: false,
      items: [
        {title: 'Estadísticas', route: '/'},
        {title: 'Escrutinio', route: '/escrutinio'},
        {title: 'Lista de Mesas', route: '/mesas'},
        {title: 'Descargas', route: '/descargas'},
      ],
    }
  }
}
</script>

<style scoped>
.navbar {
  font-family: "MyriadProCond", serif;
  font-size: 18px;
}
</style>