<template>
  <div>
    <v-row>
      <v-col cols="12" sm="8" class="titulo text-justify blue-grey--text text--darken-3 text-capitalize">{{ texto }}</v-col>
      <v-col cols="12" sm="4" class="texto blue-grey--text text--lighten-1 pt-sm-6 hidden-xs-only" align="right">
        <v-icon v-if="date" class="pr-1 pb-1" color="blue-grey lighten-1" dense> mdi-clock-outline</v-icon>
        {{ date }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TituloPrincipal",
  props: {
    texto: String
  },
  data() {
    return {
      // date: null,
    }
  },
  computed: {
    date() {
      let today = new Date();
      let date = today.getDate() +'-'+(today.getMonth()+1)+'-'+today.getFullYear()+' '
          +today.getHours()+':'+(today.getMinutes()<10?'0':'') + today.getMinutes();
      return date;
    }
  },
  watch: {
    $route() {
      // this.getData();
    }
  },
  mounted() {
    // this.getData(); To get last sync data
  },
  methods: {
    getData() {
      this.$api.get('last-sync').then((response) => {
        this.date = response.data;
      })
    }
  }
}
</script>Total Provincia

<style scoped>
.texto {
  font-family: "MyriadProCond", serif;
  font-size: 20px;
  font-weight: bold;
}
.titulo {
  font-family: "MyriadProCond", serif;
  font-size: 33px;
  font-weight: bold;
}
@media (max-width: 480px) {
  .titulo {
    font-size: 30px;
  }
}
</style>