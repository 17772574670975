<template>
  <div class="titulo blue-grey--text text--darken-3 text-capitalize">
    {{ texto }}
  </div>
</template>

<script>
export default {
  name: "titulo",
  props: {
    texto: String
  }
}
</script>

<style scoped>
.titulo {
  font-family: "MyriadProCond", serif;
  font-size: 28px;
  font-weight: bold;
}
</style>