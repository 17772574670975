<template>
  <div>
    <tabla class="mb-8" :headers="headers" :data="partidos"
           :filtro="true" :acciones="true" :loading="fetchingData"
           :footer="true" :porcentajes="porcentajes" :switch-porcentajes="true" :validos="validos" />
    <h4 class="mt-8 leyenda text-justify blue-grey--text text--darken-3">Los porcentajes de Partidos y/o Alianzas se calculan sobre el Total Votos Válidos</h4>
  </div>
</template>

<script>
import Tabla from "@/components/Tabla";
import pickBy from "lodash/pickBy";

export default {
  name: "EscrutinioPartidos",
  components: {Tabla},
  props: {
    valueKey: null,
    value: null,
  },
  data() {
    return {
      partidos: [],
      headers: [],
      fetchingData: true,
      porcentajes: true,
      totales: null,
    }
  },
  computed: {
    validos() {
      return pickBy(this.totales, function(value, key) {
        return key.endsWith("_validos") && value;
      });
    }
  },
  created() {
    this.getValidos();
  },
  watch: {
    value: function () {
      this.getValidos();
    }
  },
  methods: {
    getData() {
      this.fetchingData = true;
      let parameter = {};
      if (this.valueKey) parameter[this.valueKey] = this.value;
      this.$api.get("partidos/votos", {
        params: parameter
      }).then((response) => {
        this.headers = response.data.headers;
        this.headers.unshift({
          text: 'Partido',
          value: 'partido'
        });
        this.headers.unshift({
          text: '#',
          value: 'index'
        });
        this.headers = this.headers.map((header) => {
          header.class = 'blue-grey lighten-5 hea';
          return header;
        })
        this.partidos = response.data.data;
        this.partidos = this.partidos.map((partido, index) => {
          partido.index = index + 1;
          return partido;
        });
        this.fetchingData = false;
      });
    },
    getValidos() {
      this.fetchingData = true;
      let parameter = {};
      if (this.valueKey) parameter[this.valueKey] = this.value;
      this.$api.get("totales", {
        params: parameter
      }).then((response) => {
        this.totales = response.data;
        this.getData();
      });
    }
  }
}
</script>

<style>
.hea {
  font-family: "MyriadProCond", serif;
  font-size: 18px !important;
  font-weight: bold;
}
.leyenda {
  font-family: "MyriadProCond", serif;
  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 480px) {
  .leyenda {
    font-size: 17px;
  }
}
</style>