<template>
  <div>
    <h3 class="tituloCargo blue-grey--text text--darken-3 mt-2 text-center">Escrutinio Definitivo</h3>
    <h3 class="tituloCargo blue-grey--text text--darken-3 mt-2 text-center">¿Qué se elige en la provincia?</h3>
    <div class="mt-6 d-flex flex-column flex-sm-row flex-wrap justify-sm-space-around align-center">
      <etiqueta-cargo principal="Gobernador" secundario="VICEGOBERNADOR"/>
      <etiqueta-cargo principal="Senadores" secundario="PROVINCIALES"/>
      <etiqueta-cargo principal="Diputados" secundario="PROVINCIALES"/>
    </div>
    <h2 class="hora blue-grey--text text--lighten-1 text-right hidden-xs-only">
      <v-icon v-if="date" class="pr-1 pb-1" color="blue-grey lighten-1" small> mdi-clock-outline</v-icon>
      {{ date }}
    </h2>
    <v-row class="mt-4">
      <v-col cols="12" lg="4">
        <votos-gobernador/>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <votos-senadores/>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <votos-diputados/>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" sm="6">
        <mesas-escrutadas />
      </v-col>
      <v-col cols="12" sm="6">
        <votantes/>
      </v-col>
    </v-row>
    <!--<votos-no-validos/>-->
  </div>
</template>

<script>
import EtiquetaCargo from "@/components/EtiquetaCargo";
import VotosGobernador from "@/components/Estadisticas/VotosGobernador";
import VotosDiputados from "@/components/Estadisticas/VotosDiputados";
import VotosSenadores from "@/components/Estadisticas/VotosSenadores";
import MesasEscrutadas from "@/components/Estadisticas/MesasEscrutadas";
import Votantes from "@/components/Estadisticas/Votantes";
export default {
  name: "EstadisticasProvincia",
  components: {Votantes, MesasEscrutadas, VotosSenadores, VotosDiputados, VotosGobernador, EtiquetaCargo},
  data() {
    return {
      // date: null,
    }
  },
  computed: {
    date() {
      let today = new Date();
      let date = today.getDate() +'-'+(today.getMonth()+1)+'-'+today.getFullYear()+' '
      +today.getHours()+':'+(today.getMinutes()<10?'0':'') + today.getMinutes();
      return date;
    }
  },
  watch: {
    $route() {
      // this.getData();
    }
  },
  mounted() {
    // this.getData();
  },
  methods: {
    getData() {
      this.$api.get('last-sync').then((response) => {
        this.date = response.data;
      })
    }
  }
}
</script>

<style scoped>
.tituloCargo {
  font-family: "MyriadProCond", serif;
  font-size: 46px;
  font-weight: bold;
}
.hora{
  font-family: "MyriadProCond", serif;
  font-size: 20px;
  font-weight: normal;
}
</style>
