<template>
  <div>
    <titulo-principal texto="Escrutinio Definitivo"/>
    <titulo :texto="titulo"></titulo>
    <v-divider class="my-4"/>
    <filtro-doble/>
    <totales-escrutunio class="mb-6" :value-key="key" :value="value" v-if="key !== 'privados'"/>
    <escrutinio-partidos :value-key="key" :value="value"/>
    <section v-if="key !== 'privados'">
      <v-divider class="my-4 mt-10"/>
      <titulo texto="Votos"/>
      <totales-votos :value-key="key" :value="value"/>
    </section>
    <section v-if="key !== 'privados'">
      <v-divider class="my-4 mt-10"/>
      <alianzas :value-key="key" :value="value"/>
    </section>
  </div>
</template>

<script>
import FiltroDoble from "../components/FiltroDoble";
import Titulo from "../components/Titulo";
import TotalesEscrutunio from "@/components/Secciones/TotalesEscrutunio";
import TituloPrincipal from "../components/TituloPrincipal";
import EscrutinioPartidos from "@/components/Tablas/EscrutinioPartidos";
import Alianzas from "@/components/Secciones/Alianzas";
import TotalesVotos from "@/components/Secciones/TotalesVotos";

export default {
  name: "EscrutinioPrincipal",
  components: {
    TotalesVotos,
    Alianzas,
    EscrutinioPartidos,
    TituloPrincipal,
    TotalesEscrutunio,
    Titulo,
    FiltroDoble
  },
  data() {
    return {
      key: this.$route.params.key,
      value: this.$route.params.value,
      partidos: [],
      headers: []
    }
  },
  computed: {
    titulo() {
      let text = "provincia";
      if (this.key === "mesa_id") {
        text = "Mesas"
      }
      else if (this.key) {
        text = this.key + "s"
      }
      return "Total " + text
    }
  },
  watch: {
    $route(to) {
      this.key = to.params.key;
      this.value = to.params.value;
    }
  }

}
</script>

<style>

</style>