<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-top-transition"
    >
      <v-card align="center" justify="center">
        <v-toolbar
            dark
            color="blue-grey darken-3"
        >
          <v-btn
              icon
              dark
              @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-center justify-center">
          <titulo :texto="tituloDetalle"/>
        </v-card-title>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <card-mesas class="mt-4 pr-2" titulo="mesas escrutadas" :mesas="procesadas"/>
          </v-col>
          <v-col cols="12" md="6">
            <card-mesas class="mt-4 pl-2" titulo="mesas por escrutar" :mesas="noProcesadas"/>
          </v-col>
        </v-row>
        <v-card-actions class="align-center justify-center mt-6">
          <v-btn
              color="blue-grey darken-3"
              dark
              large
              @click="close"
              class="descripcion"
          >
            <h3>Aceptar</h3>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>
import Titulo from "./Titulo";
import CardMesas from "./CardMesas";

export default {
  name: "ModalDetalleMesas",
  components: {
    CardMesas,
    Titulo,
  },

  props: {
    show: Boolean,
    tituloDetalle: String,
    item: Object
  },

  data() {
    return {
      fetchingData: true,
      procesadas: [],
      noProcesadas: []
    }
  },

  watch: {
    item: function () {
      this.getData();
    }
  },

  methods: {
    close() {
      this.$emit("close")
    },
    getData() {
      this.fetchingData = true;
      let arrayRango = this.item.rango.split(" ")
      let parameter = {};
      parameter.desde = arrayRango[0];
      parameter.hasta = arrayRango[2];
      if (this.valueKey) parameter[this.valueKey] = this.value;
      this.$api.get("mesas/detalle", {
        params: parameter
      }).then((response) => {
        this.procesadas = response.data.filter((mesa) => { return mesa.esta_cerrada })
        this.noProcesadas = response.data.filter((mesa) => { return !mesa.esta_cerrada })
        this.fetchingData = false;
      });
    }
  }
}
</script>

<style>
.descripcion {
  font-family: "MyriadProCond", serif;
  font-size: 20px;
}
</style>