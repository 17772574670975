<template>
  <v-card elevation="5" class="mt-6">
    <titulo texto="Electores" class="mt-4 text-center pt-4"/>
    <chart-torta class="mt-2" chart-id="chart-electores" :data="data" :fetching-data="fetchingData"/>
  </v-card>
</template>

<script>
import ChartTorta from "@/components/Charts/ChartTorta";
import Titulo from "@/components/Titulo";
export default {
  name: "Votantes",
  components: {Titulo, ChartTorta},
  data() {
    return {
      data: [],
      fetchingData: false
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.fetchingData = true;
      this.$api.get("estadisticas/electores").then((response) => {
        this.data = response.data;
        this.fetchingData = false;
      });
    }
  }
}
</script>

<style scoped>

</style>