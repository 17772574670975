<template>
  <v-card elevation="5">
    <titulo texto="Votos por alianza de Gobernador/Vice" class="mt-4 text-center pt-4"/>
    <torta-votos class="mt-2" chart-id="gob-vice" :data="data" :fetching-data="fetchingData"/>
<!--    <barra-horizontal class="mt-6" chart-id="gob-vice" :data="data" :fetching-data="fetchingData"/>-->
  </v-card>
</template>

<script>
import Titulo from "@/components/Titulo";
import TortaVotos from "@/components/Charts/TortaVotos";
export default {
  name: "VotosGobernador",
  components: {TortaVotos, Titulo},
  data() {
    return {
      data: [],
      fetchingData: false
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.fetchingData = true;
      this.$api.get("estadisticas/votos-gobernador").then((response) => {
        this.data = response.data;
        this.fetchingData = false;
      });
    }
  }
}
</script>

<style scoped>

</style>