<template>
  <div>
    <v-skeleton-loader
        class="mx-auto"
        type="image"
        transition="scale-transition"
        origin="center center"
        v-if="fetchingData"
    />
    <div v-else>
      <subtitulo :texto="titular"/>
      <div v-if="valueKey==='escuela'">
        <descripcion-direccion :texto1="municipio" :texto2="direccion" />
      </div>
      <div v-if="valueKey==='mesa_id'">
        <descripcion-direccion :texto1="municipio" :texto2="direccion" :texto3="', ' + circuito" />
      </div>

      <v-row no-gutters>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <div class="d-inline-flex">
            <span class="textoTotales blue-grey--text text--lighten-1 mr-2">Electores:</span>
            <span class="textoTotales blue-grey--text text--darken-3" v-text="electores"></span>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <div class="d-inline-flex">
            <span class="textoTotales blue-grey--text text--lighten-1 mr-2">Sobres:</span>
            <span class="textoTotales blue-grey--text text--darken-3" v-text="sobres"></span>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <div class="d-inline-flex">
            <span class="textoTotales blue-grey--text text--lighten-1 mr-2">Votantes:</span>
            <span class="textoTotales blue-grey--text text--darken-3">
            {{ votantes }}
            <span style="font-size: 18px">
              ({{ porcentajeVotantes }}%)
            </span>
          </span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <div class="d-inline-flex">
            <span class="textoTotales blue-grey--text text--lighten-1 mr-2">Mesas:</span>
            <span class="textoTotales blue-grey--text text--darken-3">
            {{ mesas }}
            <span style="font-size: 18px">
              {{ this.intervaloRango }}
            </span>
          </span>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <div class="d-inline-flex">
            <span class="textoTotales blue-grey--text text--lighten-1 mr-2">Escrutadas:</span>
            <span class="textoTotales blue-grey--text text--darken-3">
            {{ escrutadas }}
            <span style="font-size: 18px">
              ({{ porcentajeEscrutadas }}%)
            </span>
          </span>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <div class="d-inline-flex">
            <span class="textoTotales blue-grey--text text--lighten-1 mr-2">Por escrutar:</span>
            <span class="textoTotales blue-grey--text text--darken-3" v-text="porEscrutar"></span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <div class="d-inline-flex">
            <span class="textoTotales blue-grey--text text--lighten-1 mr-2">Resultado:</span>
            <span class="textoTotales blue-grey--text text--darken-3" v-text="this.resultado"></span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="valueKey==='privados-libertad'">
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <div class="d-inline-flex">
            <span class="textoTotales blue-grey--text text--lighten-1 mr-2">Diferencias:</span>
            <span class="textoTotales blue-grey--text text--darken-3">0</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Subtitulo from "../Subtitulo";
import DescripcionDireccion from "../DescripcionDireccion";

export default {
  name: "TotalesEscrutunio",
  components: {Subtitulo,   DescripcionDireccion},
  props: {
    valueKey: null,
    value: null
  },
  data() {
    return {
      municipio: null,
      direccion: null,
      circuito: null,
      sobres: 0,
      electores: 0,
      votantes: 0,
      mesas: 0,
      mesaDesde: 0,
      mesaHasta: 0,
      escrutadas: 0,
      fetchingData: true,
    }
  },
  computed: {
    titular() {
      if (Number.isInteger(this.value)) return "Mesa Nro. " + this.value;
      return this.value ? this.value.replaceAll("-", " ") : null;
    },
    porcentajeVotantes() {
      return Math.round(this.votantes * 100 / this.electores * 100) / 100;
    },
    intervaloRango() {
      if (!this.valueKey)
        return "";
      return "[" + this.mesaDesde + " a " + this.mesaHasta + "]";
    },
    porcentajeEscrutadas() {
      return Math.round(this.escrutadas * 100 / this.mesas * 100) / 100;
    },
    porEscrutar() {
      return this.mesas - this.escrutadas;
    },
    resultado() {
      return this.porEscrutar === 0 ? 'Final' : 'Parcial';
    }
  },
  created() {
    this.getData();
  },
  watch: {
    value: function () {
      this.getData();
    }
  },
  methods: {
    // Gets sobres, electores, votantes, mesas, mesas escrutadas and rango de mesas from API
    // If valueKey is null then it means it must return the value for Provincia (so no parameters required)
    getData() {
      this.fetchingData = true;
      let parameter = {};
      if (this.valueKey) parameter[this.valueKey] = this.value;
      this.$api.get("mesas/totales", {
        params: parameter
      }).then((response) => {
        this.municipio = response.data.municipio;
        this.direccion = response.data.direccion;
        this.circuito = response.data.circuito;
        this.sobres = response.data.sobres;
        this.electores = response.data.electores;
        this.votantes = response.data.votantes;
        this.mesas = response.data.mesas;
        this.mesaDesde = response.data.mesa_desde;
        this.mesaHasta = response.data.mesa_hasta;
        this.escrutadas = response.data.escrutadas;
        this.fetchingData = false;
      });
    }
  }
}
</script>

<style>
.textoTotales {
  font-family: "MyriadProCond", serif;
  font-size: 24px;
  font-weight: bold;
}
</style>