<template>
  <div>
    <v-card>
      <div class="text-center">
        <div class="texto blue-grey--text text--darken-2 text-capitalize mr-3">{{titulo}}</div>
      </div>
      <v-divider class="mx-4"/>
      <v-card-text class="mt-3 text-center">
        <v-row class="mb-3">
          <v-col cols="3" v-for="mesa in mesas" :key="mesa.id">
            <div class="lista blue-grey--text text--darken-2">
              {{mesa.id}}
            </div>
          </v-col>
          <v-col v-if="mesas.length === 0">
            No hay datos disponibles.
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CardMesas",
  props: {
    titulo: String,
    mesas: Array,
  }
}
</script>

<style>
.texto {
  font-family: "MyriadProCond", serif;
  font-size: 32px;
  font-weight: bold;
}
.lista {
  font-family: "MyriadProCond", serif;
  font-size: 30px;
}
</style>