<template>
  <div>
    <titulo-principal texto="Escrutinio Definitivo"/>
    <titulo texto="Descargas"/>
    <v-divider class="my-4"/>
    <filtro-doble tipo="descargas" />
    <v-divider class="my-4"/>
    <item-descarga v-for="file in files" :file="file" :key="file.id" />
    <div class="headline" v-if="files.length === 0">No se encuentran archivos de este tipo.</div>
    <v-pagination
        v-else
        color="blue-grey darken-1"
        class="numero"
        v-model="page"
        :total-visible="10"
        :length="length"
        circle
    />
  </div>
</template>

<script>
import ItemDescarga from "../components/ItemDescarga"
import TituloPrincipal from "@/components/TituloPrincipal";
import Titulo from "@/components/Titulo";
import FiltroDoble from "@/components/FiltroDoble";

export default {
  name: "Descargas",
  components: {FiltroDoble, Titulo, TituloPrincipal, ItemDescarga},
  data () {
    return {
      page: 1,
      fetchingData: false,
      files: [],
      length: 0,
      key: this.$route.params.key,
      value: this.$route.params.value
    }
  },
  watch: {
    page() {
      this.getData();
    },
    $route(to) {
      this.key = to.params.key;
      this.value = to.params.value;
      this.page = 1;
    },
    value() {
      this.getData();
    }
  },
  created() {
    this.getData();
  },
  methods: {
      getData() {
        this.fetchingData = true;
        let parameter = {};
        if (this.key) parameter[this.key] = this.value;
        this.$api.get("file/list?page=" + this.page, {
          params: parameter
        }).then((response) => {
          this.files = response.data.data;
          this.length = Math.ceil(response.data.meta.total / 10);
          this.fetchingData = false;
        });
      },
  }
}
</script>
<style>
.numero {
  font-family: "MyriadProCond", serif;
  color: #263238;
}
</style>