<template>
  <section>
    <titulo class="mt-6" texto="Conformación de Alianzas"/>
    <filtro-cargos :value-key="valueKey" :value="value" @changed="changed"/>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <subtitulo :texto="'Votos por alianza de ' + cargo"/>
        <escrutinio-alianzas :value-key="valueKey" :value="value" :cargo="cargo"/>
        <h4 class="mt-8 leyenda text-justify blue-grey--text text--darken-3">Los porcentajes de Partidos y/o Alianzas se calculan sobre el Total Votos Válidos</h4>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <subtitulo class="mb-6" texto="Detalle de partidos"/>
        <expand :value-key="valueKey" :value="value" :cargo="cargo" class="mx-3"/>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import FiltroCargos from "@/components/Filtros/FiltroCargos";
import Titulo from "@/components/Titulo";
import Subtitulo from "@/components/Subtitulo";
import Expand from "@/components/Expands/VotosPorPartidoPorAlianzas";
import EscrutinioAlianzas from "@/components/Tablas/EscrutinioAlianzas";
export default {
  name: "Alianzas",
  components: {EscrutinioAlianzas, Expand, Subtitulo, Titulo, FiltroCargos},
  props: {
    valueKey: null,
    value: null
  },
  data () {
    return {
      cargo: null
    }
  },
  methods: {
    changed(value) {
      this.cargo = value
    }
  }
}
</script>

<style scoped>
.leyenda {
  font-family: "MyriadProCond", serif;
  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 480px) {
  .leyenda {
    font-size: 17px;
  }
}
</style>