<template>
  <div>
    <h3 class="texto blue-grey--text text--darken-3 mb-3">Filtrar por:</h3>
    <v-row class="pb-0">
      <v-col class="pb-0" cols="12" sm="6" md="4" xl="3">
        <v-autocomplete
            :items="items"
            v-model="selected"
            outlined
            dense
            label="Cargo"
            color="blue-grey"
            @change="changed"
            item-color="blue-grey darken-1"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "filtro-simple",
  props: {
    items: [],
    item: null
  },
  data() {
    return {
      selected: null
    }
  },
  watch: {
    item(value) {
      this.selected = value;
    }
  },
  created() {
    this.selected = this.item;
  },
  methods: {
    changed(value) {
      this.$emit("changed", value);
    }
  }
}
</script>

<style scoped>
.texto {
  font-family: "MyriadProCond", serif;
  font-size: 20px;
  font-weight: bold;
}
</style>