<template>
  <div>
    <v-speed-dial
        v-model="fab"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
    >
      <template v-slot:activator>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">

            <v-btn
                small
                v-model="fab"
                color="blue-grey darken-1 white--text"
                fab
                v-bind="attrs"
                v-on="on"
            >
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-format-list-bulleted-square
              </v-icon>
            </v-btn>
          </template>
          <span>Acciones</span>
        </v-tooltip>

      </template>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              fab
              dark
              small
              color="blue-grey darken-3"
              v-bind="attrs"
              v-on="on"
              @click="download"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
        <span>Imprimir</span>
      </v-tooltip>


      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              dark
              small
              color="blue-grey darken-2"
              @click="downloadCSV"
          >
            CSV
          </v-btn>
        </template>
        <span>Descargar</span>
      </v-tooltip>

      <v-tooltip bottom v-if="botonTelegrama">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              dark
              small
              color="blue-grey darken-1"
              @click="showTelegrama = true"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>Ver Telegrama</span>
      </v-tooltip>

    </v-speed-dial>
    <v-overlay :value="fetchingData">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="showTelegrama" width="600">
      <v-img v-if="telegramaPath" :src="telegramaPath" contain/>
      <v-card v-else>
        <h3 class="text text-center my-auto py-12 blue-grey--text text--darken-3 font-weight-bold">Este telegrama aún no
          se encuentra disponible.</h3>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
export default {
  data: () => ({
    direction: 'left',
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    transition: 'slide-x-reverse-transition',
    fetchingData: false,
    showTelegrama: false,
    telegramaPath: false,
  }),
  computed: {
    pdfRoute() {
      let uri = 'file/pdf/generate?';
      if (this.$route.fullPath.includes('/mesas')) uri = 'file/pdf/mesas/generate?';
      return process.env.VUE_APP_API_URL + uri + this.$route.params.key + '=' + this.$route.params.value
    },
    pdfName() {
      if (this.$route.fullPath.includes('/mesas')) return 'Informe Listado de mesas.pdf';
      return 'Informe Escrutinio.pdf';
    },
    csvRoute() {
      let uri = 'file/csv/generate?';
      if (this.$route.fullPath.includes('/mesas')) uri = 'file/csv/mesas/generate?';
      return process.env.VUE_APP_API_URL + uri + this.$route.params.key + '=' + this.$route.params.value
    },
    csvName() {
      if (this.$route.fullPath.includes('/mesas')) return 'Informe Listado de mesas.csv';
      return 'Informe Escrutinio.csv';
    },
    botonTelegrama() {
      return this.$route.fullPath.includes('/mesa_id')
    },
  },
  watch: {
    $route() {
      if (this.$route.params.key === 'mesa_id') {
        this.getTelegrama(this.$route.params.value)
      }
    }
  },
  mounted() {
    if (this.$route.params.key === 'mesa_id') {
      this.getTelegrama(this.$route.params.value)
    }
  },
  methods: {
    download() {
      this.fetchingData = true;
      this.$api({
        url: this.pdfRoute,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.pdfName);
        document.body.appendChild(link);
        link.click();
        this.fetchingData = false;
      }).catch(() => {
        this.fetchingData = false;
        alert('Ha ocurrido un error, vuelva a intentar en unos minutos.')
      });
    },
    downloadCSV() {
      this.fetchingData = true;
      this.$api({
        url: this.csvRoute,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.csvName);
        document.body.appendChild(link);
        link.click();
        this.fetchingData = false;
      }).catch(() => {
        this.fetchingData = false;
        alert('Ha ocurrido un error, vuelva a intentar en unos minutos.')
      });
    },
    getTelegrama(id) {
      this.fetchingData = true;
      this.$api.get('/telegrama/' + id).then((response) => {
        this.fetchingData = false;
        this.telegramaPath = response.data;
      }).catch(() => {
        this.fetchingData = false;
        alert('Ha ocurrido un error, vuelva a intentar en unos minutos.')
      })
    },
  }
}
</script>