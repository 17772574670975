import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import * as echarts from 'echarts'
import VueGtag from "vue-gtag";


Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    });
  }
})

Vue.use(VueGtag, {
  config: { id: "G-SQ91NDB7NN" },
}, router);

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
