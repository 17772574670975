<template>
  <div>
    <v-skeleton-loader
        class="mx-auto"
        type="image"
        transition="scale-transition"
        origin="center center"
        v-if="fetchingData"
    />
  <v-row v-else>
    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
      <expand-votos-totales class="mt-6 px-3" titulo="Total de Votos Generales" :items="generales"/>
    </v-col>
    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
      <expand-votos-totales class="mt-6 px-3" titulo="Total de Votos Válidos" :items="validos"/>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import pickBy from "lodash/pickBy";
import ExpandVotosTotales from "@/components/Expands/ExpandVotosTotales";
export default {
  name: "TotalesVotos",
  components: {ExpandVotosTotales},
  props: {
    valueKey: null,
    value: null
  },
  data() {
    return {
      votos: [],
      fetchingData: true,
    }
  },
  computed: {
    generales() {
      return pickBy(this.votos, function(value, key) {
        return key.endsWith("_generales") && value;
      });
    },
    validos() {
      return pickBy(this.votos, function(value, key) {
        return key.endsWith("_validos") && value;
      });
    }
  },
  watch: {
    value: function () {
      this.getData();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.fetchingData = true;
      let parameter = {};
      if (this.valueKey) parameter[this.valueKey] = this.value;
      this.$api.get("totales", {
        params: parameter
      }).then((response) => {
        this.votos = response.data;
        this.fetchingData = false;
      });
    }
  }
}
</script>

<style scoped>

</style>