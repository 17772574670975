<template>
  <tabla :headers="headers" :data="alianzas" :loading="fetchingData"
         :footer="true" :porcentajes="true" :switch-porcentajes="true" switch-class="" />
</template>

<script>
import Tabla from "@/components/Tabla";
export default {
  name: "EscrutinioAlianzas",
  components: {Tabla},
  props: {
    valueKey: null,
    value: null,
    cargo: null
  },
  data() {
    return {
      alianzas: [],
      headers: [
        {
          value: "alianza",
          text: "Alianza",
          class: "blue-grey lighten-5 hea"
        },
        {
          value: "votos",
          text: "Votos",
          class: "blue-grey lighten-5 hea"
        },
      ],
      fetchingData: true,
    }
  },
  watch: {
    cargo: function () {
      this.getData();
    },
    value: function () {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.fetchingData = true;
      let parameter = {};
      parameter.cargo = this.cargo;
      if (this.valueKey) parameter[this.valueKey] = this.value;
      this.$api.get("alianzas/votos", {
        params: parameter
      }).then((response) => {
        this.alianzas = response.data.data;
        this.fetchingData = false;
      });
    }
  }
}
</script>

<style>
.hea {
  font-family: "MyriadProCond", serif;
  font-size: 18px !important;
  font-weight: bold;
}
</style>