<template>
  <div>
    <v-row>
      <v-expansion-panels accordion v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3 class="titulo blue-grey--text text--darken-2">{{titulo}}</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="items blue-grey--text text--darken-2" v-for="(item, i) in items" :key="i">
              <v-row>
                <v-col class="text-capitalize">{{ readify(i) }}</v-col>
                <v-col class="font-weight-bold" align="right">{{item}}</v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ExpandVotosTotales",
  props: {
    titulo: String,
    items: Object,
  },
  data() {
    return {
      panel: 0,
    }
  },
  methods: {
    readify(string) {
      return string.substring(0, string.indexOf("_")).replaceAll("-", " ")
    }
  }
}
</script>

<style scoped>
.titulo{
  font-family: "MyriadProCond", serif;
  font-size: 25px;
  font-weight: bold;
}
.items{
  font-family: "MyriadProCond", serif;
  font-size: 20px;
}
</style>