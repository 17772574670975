<template>
  <div>
    <v-row
        justify="space-between">
      <v-col cols="12" sm="6" md="2" v-for="button in buttons" :key="button.text">
        <v-btn color="blue-grey lighten-5" block :to="button.to" exact>
          <v-icon dense color="blue-grey darken-4" v-text="button.icon"/>
          <div class="texto blue-grey--text text--darken-4 ml-1" v-text="button.text"></div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Botones",
  data: () => ({
    buttons: [
      {
        text: "General",
        icon: 'mdi-clipboard-text',
        to: "/mesas",
      },
      {
        text: "Departamentos",
        icon: 'mdi-map-marker',
        to: "/mesas/departamentos",
      },
      {
        text: "Municipios",
        icon: 'mdi-map-marker',
        to: "/mesas/municipios",
      },
      {
        text: "Circuitos",
        icon: 'mdi-vote',
        to: "/mesas/circuitos",
      },
      {
        text: "Escuelas",
        icon: 'mdi-school',
        to: "/mesas/escuelas",
      },
    ],
  }),
}
</script>

<style scoped>
.texto {
  font-family: "MyriadProCond", serif;
  font-size: 18px;
  font-weight: bold;
}
</style>