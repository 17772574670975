<template>
  <div>
    <titulo-principal texto="Escrutinio Definitivo"/>
    <titulo texto="Estado de las Mesas"/>
    <botones class="mt-6"/>
    <v-divider class="my-8"/>
    <totales-escrutunio :key="null" :value-key="null"/>
    <listado-mesas :value-key="key"/>
  </div>
</template>

<script>
import Titulo from "@/components/Titulo";
import Botones from "@/components/Botones";
import TituloPrincipal from "@/components/TituloPrincipal";
import TotalesEscrutunio from "@/components/Secciones/TotalesEscrutunio";
import ListadoMesas from "@/components/Secciones/ListadoMesas";

export default {
  name: "ListaMesas",
  components: {ListadoMesas, TotalesEscrutunio, TituloPrincipal, Botones, Titulo},
  data() {
    return {
      key: this.$route.params.key,
    }
  },
  watch: {
    $route(to) {
      this.key = to.params.key;
    }
  }

}
</script>

<style scoped>

</style>