<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="8" lg="8">
        <div class="d-flex flex-row">
          <div class="d-none d-md-flex mt-2 mr-2">
            <v-img
                :src="imagen"
                height="32"
                width="32"
                alt="Archivo"
            />
          </div>

          <div>
            <subtitulo :texto="name "/>
            <span>{{ file.created_at }}</span>
          </div>

        </div>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="4" class="d-flex justify-center justify-sm-end justify-lg-end">

        <div class="d-none d-sm-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="ma-5"
                  small
                  fab
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  @click="download"
              >
                <v-icon color="blue-grey darken-3"> mdi-download </v-icon>
              </v-btn>
            </template>
            <span>Descargar</span>
          </v-tooltip>
        </div>

        <div class="d-flex d-sm-none pb-7">
          <v-btn
              class="text"
              block
              elevation="2"
              @click="download"
          >
            Descargar <v-icon color="blue-grey darken-3"> mdi-download </v-icon>
          </v-btn>
        </div>

      </v-col>
    </v-row>
    <v-divider class="my-3"/>
    <v-overlay :value="fetchingData">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>

</template>

<script>
import Subtitulo from "./Subtitulo";

export default {
  components: {
    Subtitulo
  },
  props: {
    file: {},
  },
  data() {
    return {
      fetchingData: false
    }
  },
  computed: {
    name() {
      return this.file.data + ' ' + this.file.filter + ' ' + this.file.type
    },
    imagen() {
      if (this.file.type.slice(-3) === 'csv') return '/img/extension-de-formato-de-archivo-csv.png';
      if (this.file.type.slice(-3) === 'pdf') return '/img/extension-de-formato-de-archivo-pdf.png';
      return null;
    }
  },
  methods: {
    download() {
      this.fetchingData = true;
      this.$api({
        url: process.env.VUE_APP_API_URL + 'file/pdf/' + this.file.id,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.name);
        document.body.appendChild(link);
        link.click();
        this.fetchingData = false;
      }).catch(() => {
        this.fetchingData = false;
        alert('Ha ocurrido un error, vuelva a intentar en unos minutos.')
      });
    }
  }
}
</script>

<style>
.text {
  font-family: "MyriadProCond", serif;
  font-size:28px;
  font-weight: bold;
}
</style>