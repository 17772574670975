<template>
  <div>
    <div class="d-inline-flex flex-row mb-6 align-center">
      <div class="justify-center">
        <v-icon x-large color="blue-grey darken-3" class="hidden-sm-and-down">
          mdi-face-woman
        </v-icon>
        <v-icon x-large color="blue-grey darken-3" class="hidden-sm-and-down" >
          mdi-face
        </v-icon>
        <v-icon size="30px" color="blue-grey darken-3" class="hidden-md-and-up">
          mdi-face-woman
        </v-icon>
        <v-icon size="30px" color="blue-grey darken-3" class="hidden-md-and-up">
          mdi-face
        </v-icon>
      </div>
      <div class="d-flex flex-column ml-2">
        <p class="principal text-uppercase blue-grey--text text--darken-3 text-left" style="margin : 0; padding-top:0;">
           {{ principal }}
        </p>
        <p class="secundario blue-grey--text text--lighten-1 font-weight-light text-left" style="margin : 0; padding-top:0;">
          {{ secundario }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EtiquetaCargo",
  props:{
    principal: String,
    secundario: String
  }
}
</script>

<style>
.principal{
  font-family: "MyriadProCond", serif;
  font-size: 31px;
  font-weight: bold;
  letter-spacing: 2px;
}
.secundario{
  font-family: "MyriadProCond", serif;
  font-size: 24px;
  letter-spacing: 2px;
}
p{
  line-height : 24px;
}


@media (max-width: 800px) and (min-width: 600px) {
  .principal {
    font-size: 19px;
  }
  .secundario {
    font-size: 17px;
  }
  p{
    line-height : 20px;
  }
}
</style>