<template>
  <div>
    <v-app>
      <navbar/>
      <v-main class="mt-4 mb-8 mx-4 mx-sm-10 mx-lg-16">
        <router-view/>
      </v-main>
      <Footer/>
    </v-app>
  </div>
</template>

<script>

import navbar from "@/layout/Navbar";
import Footer from "@/layout/Footer";
export default {
  name: 'App',
  components: {Footer, navbar},
};
</script>

<style>

@font-face {
  font-family: MyriadPro-BoldCond;
  src: url('/fonts/MyriadPro-BoldCond.otf');
}

@font-face {
  font-family: MyriadProCond;
  src: url('/fonts/MyriadPro-Cond.otf');
}

.MyriadProCond {
  font-family: "MyriadProCond";
}

</style>
