<template>
  <div>
    <estadisticas-provincia/>
  </div>
</template>

<script>
import EstadisticasProvincia from "@/views/EstadisticasProvincia";
export default {
  name: "Estadisticas",
  components: {EstadisticasProvincia},
  data () {
    return{
      tabs: null,
    }
  }
}

</script>
<style>
.textBtn {
  font-family: "MyriadProCond", serif;
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 480px) {
  .textBtn {
    font-size: 17px;
  }
}
</style>