<template>
  <tabla :telegrama="false" :acciones="true"
         :headers="headers" :data="mesas"
         :filtro="true" :procesar="true"
         :loading="fetchingData" :items-per-page="10"
         @changed="changed"
  />
</template>

<script>
import Tabla from "@/components/Tabla";
export default {
  name: "ListadoMesas",
  components: {Tabla},
  props: {
    valueKey: null,
  },
  data() {
    return {
      headers: [],
      mesas: [],
      fetchingData: true,
    }
  },
  watch: {
    valueKey: function () {
      this.getData();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData(estado = null) {
      this.fetchingData = true;
      let parameter = {};
      if (!(estado == null)) {
        parameter["estado"] = estado
      }
      if (this.valueKey) parameter[this.valueKey] = "present";
      this.$api.get("mesas/listado", {
        params: parameter
      }).then((response) => {
        this.headers = response.data.headers;
        if(this.valueKey) this.headers.push({"value": "ver", "text": "Detalle"})
        this.headers.map(header => {
          return header.class = "blue-grey lighten-5 hea";
        })
        // this.mesas = response.data.mesas;
        console.log(estado)
        if (estado === 1 || estado === 0) {
          estado = estado === 1;
          this.mesas = response.data.mesas.filter(item => item.estado === estado);
        }
        else {
          this.mesas = response.data.mesas;
        }
        this.fetchingData = false;
      });
    },
    changed(value) {
      this.getData(value);
    }
  }
}
</script>

<style>
.hea {
  font-family: "MyriadProCond", serif;
  font-size: 18px !important;
  font-weight: bold;
}
</style>
