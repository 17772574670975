<template>
  <div>
    <h3 class="letra blue-grey--text text--darken-3 mb-3 text-left font-weight-bold">Filtrar por:</h3>
    <v-row no-gutters>
      <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
      >
        <v-autocomplete
            :items="keys"
            v-model="key"
            @change="keyChanged"
            outlined
            dense
            class="mr-sm-3 mr-md-5"
            label="Seleccione un filtro"
            color="blue-grey"
            item-color="blue-grey darken-1"
        />
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
      >
        <v-autocomplete
            :items="values"
            v-model="value"
            :key="value"
            @change="valueChanged"
            outlined
            dense
            class="ml-sm-3 ml-md-0 mr-md-5"
            label="Dato"
            color="blue-grey"
            item-color="blue-grey darken-1"
        />
      </v-col>
<!--      <v-col class="mb-2">-->
<!--        <botones-link v-if="tableroYmapa"/>-->
<!--      </v-col>-->
    </v-row>
  </div>
</template>

<script>
// import BotonesLink from "@/components/BotonesLink";
export default {
  name: "filtro-doble",
  // components: {BotonesLink},
  props: {
    tipo: { type: String, default: 'escrutinio' }
  },
  data() {
    return {
      keys: [],
      values: [],
      key: this.$route.params.key,
      value: this.$route.params.value
    }
  },
  computed: {
    tableroYmapa() {
      if (this.$route.path.includes('/descargas')) return false;
      return this.key === null || this.key?.text === 'Provincia'
    }
  },
  created() {
    this.getKeys();
    if (this.value) this.getValues();
  },
  methods: {
    // Responsible for getting the keys from API
    getKeys() {
      this.$api.get("filtro/keys").then((response) => {
        this.keys = response.data;
        if (!this.key) {
          this.key = this.keys[0]
        }
      });
    },
    // Responsible for getting the value from API given a certain key
    getValues() {
      if (!this.key) return;
      this.$api.get("filtro/values", {
        params: {
          key: this.key
        }
      }).then((response) => (this.values = response.data));
    },
    // Updates route if "Provincia" is selected, otherwise calls getValues
    keyChanged() {
      this.values = [];
      this.value = null;
      if (!this.key) {
        if (this.tipo === 'escrutinio') {
          this.$router.push({name: "escrutinio-home"});
        }
        else {
          this.$router.push({name: "descargas-home"});
        }
        return;
      }
      this.getValues();
    },
    // Updates route given the key and value values
    valueChanged() {
      if (this.tipo === 'escrutinio') {
        this.$router.push({
          name: "escrutinio",
          params: {
            key: this.key,
            value: this.value
          }
        });
      }
      else {
        this.$router.push({
          name: "descargas",
          params: {
            key: this.key,
            value: this.value
          }
        });
      }
    }
  }
}
</script>

<style>
.letra {
  font-family: "MyriadProCond", serif;
  font-size: 20px;
}
</style>