<template>
  <div>
    <v-skeleton-loader
        class="mx-auto"
        type="image"
        transition="scale-transition"
        origin="center center"
        v-if="fetchingData"
    />
    <v-row>
      <v-expansion-panels accordion v-model="panel" v-if="!fetchingData">
        <v-expansion-panel v-for="(alianza,i) in alianzasGrouped" :key="i">
          <v-expansion-panel-header>
            <h3 class="alianza blue-grey--text text--darken-2" v-text="alianza[0].alianza"></h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="partidos blue-grey--text text--darken-2">
              <ul>
                <li v-for="(partido, j) in alianza" :key="j">
                  <v-row>
                    <v-col>{{ partido.partido }}</v-col>
                    <v-col class="font-weight-bold" align="right">{{ partido.votos }}</v-col>
                  </v-row>
                </li>
              </ul>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy"
export default {
  name: "Expand",
  props: {
    valueKey: null,
    value: null,
    cargo: null
  },
  data() {
    return {
      panel: 0,
      alianzas: [],
      fetchingData: true
    }
  },
  computed: {
    alianzasGrouped() {
      return groupBy(this.alianzas, function (n){
        return n.alianza;
      })
    }
  },
  watch: {
    cargo: function () {
      this.getData();
    },
    value: function () {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.fetchingData = true;
      let parameter = {};
      parameter.cargo = this.cargo;
      if (this.valueKey) parameter[this.valueKey] = this.value;
      this.$api.get("alianzas/partidos/votos", {
        params: parameter
      }).then((response) => {
        this.alianzas = response.data.data;
        this.fetchingData = false;
      });
    }
  }
}
</script>

<style scoped>
.alianza {
  font-family: "MyriadProCond", serif;
  font-size: 20px;
  font-weight: bold;
}

.partidos {
  font-family: "MyriadProCond", serif;
  font-size: 18px;
}
</style>