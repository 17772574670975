<template>
  <filtro-simple :items="this.cargos" :item="cargo" @changed="changed" />
</template>

<script>
import FiltroSimple from "@/components/FiltroSimple";
export default {
  name: "FiltroCargos",
  components: {FiltroSimple},
  props: {
    valueKey: null,
    value: null
  },
  data() {
    return {
      cargos: [],
      cargo: null
    }
  },
  watch: {
    cargo(value) {
      this.$emit("changed", value)
    },
    value: function () {
      this.getData();
    }
  },
  created() {
    this.getData();
  },

  methods: {
    getData() {
      let parameter = {};
      if (this.valueKey) parameter[this.valueKey] = this.value;
      this.$api.get("cargos", {
        params: parameter
      }).then((response) => {
        this.cargos = response.data;
        this.cargo = this.cargos[0].value;
      });
    },
    changed(value) {
      this.cargo = value;
    }
  }
}
</script>

<style scoped>

</style>