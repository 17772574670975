<template>
  <div>
    <div class="subtitulo text-justify blue-grey--text text--darken-3 text-capitalize">
      {{ texto }}
    </div>
  </div>
</template>

<script>
export default {
  name: "subtitulo",
  props: {
    texto: String
  }
}
</script>
<style>
.subtitulo {
  font-family: "MyriadProCond", serif;
  font-size:23px;
  font-weight: bold;
}
</style>