import Vue from 'vue'
import VueRouter from 'vue-router'
import Escrutinio from "../views/Escrutinio";
import ListaMesas from "@/views/ListaMesas";
import Descargas from "../views/Descargas";
import Estadisticas from "@/views/Estadisticas";
import EstadisticasProvincia from "@/views/EstadisticasProvincia";


Vue.use(VueRouter)

const routes = [
    {
        path: '/escrutinio',
        name: 'escrutinio-home',
        component: Escrutinio,
        meta: {
            key: "provincia",
            value: ""
        }
    },
    {
        path: '/escrutinio/:key/:value',
        name: 'escrutinio',
        component: Escrutinio,
    },
    {
        path: '/mesas',
        name: 'mesas-home',
        component: ListaMesas
    },
    {
        path: '/mesas/:key',
        name: 'mesas',
        component: ListaMesas
    },
    {
        path: '/descargas',
        name: 'descargas-home',
        component: Descargas
    },
    {
        path: '/descargas/:key/:value',
        name: 'descargas',
        component: Descargas
    },
    {
        path: '/',
        name: 'estadisticas',
        component: Estadisticas
    },
    {
        path: '/estadisticas',
        name: 'estadisticasProvincia',
        component: EstadisticasProvincia
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
