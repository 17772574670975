<template>
  <v-row class="pb-2">
        <v-col cols="12">
          <h1 class="descripcion text-justify blue-grey--text text--lighten-1 text-capitalize">
            {{ texto1 }},&nbsp;{{ texto2 }} {{ texto3 }}
          </h1>
        </v-col>
  </v-row>
</template>

<script>
export default {
  name: "subtitulo",
  props: {
    texto1: String,
    texto2: String,
    texto3: String,
  }
}
</script>
<style>
.descripcion {
  font-family: "MyriadProCond", serif;
  font-size: 15px;
  font-weight: bold;
}
</style>

