<template>
  <div :id="chartId" style="width: 100%;height:350px;"></div>
</template>

<script>
export default {
  name: "TortaVotos",
  props:{
    chartId: null,
    data: null,
    fetchingData: null
  },
  watch: {
    fetchingData(value) {
      if (!value) {
        this.drawChart();
      }
    }
  },
  methods: {
    drawChart() {
      let chartDom = document.getElementById(this.chartId);
      let myChart = this.$echarts.init(chartDom);
      let option = {
        legend: {
          top: '2%',
          left: 'center',
          itemHeight: 24,
          textStyle:{
            color: '#37474F',
            fontWeight: 'bold',
            fontFamily: 'MyriadProCond',
            fontSize: '17',
          },
          selectedMode: false,
        },
        series: [
          {
            type: 'pie',
            top:'7%',
            radius: ['40%', '70%'],
            labelLayout: {
              hideOverlap: false,
            },
            label: {
              position:'inside',
              formatter: '{c}\n({d}%)',
              minMargin: 5,
              edgeDistance: 5,
              lineHeight: 20,
              color: '#37474F',
              fontWeight: 'bold',
              fontFamily: 'MyriadProCond',
              fontSize: '20',
            },
            data: [
              {value: this.data[0].votos, name: this.data[0].alianza},
              {value: this.data[1].votos, name: this.data[1].alianza}
            ],
          },
        ],
        color: [this.data[0].color, this.data[1].color],
      };

      myChart.setOption(option);
    }
  }
}
</script>

<style scoped>

</style>