<template>
  <div class="titulo">
    <v-row class="justify-space-between">

      <v-col
          cols="12"
          sm="4"
          v-if="filtro"
      >
        <v-text-field
            color="blue-grey"
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            hide-details
            outlined
            class="pt-sm-10 mb-2 mb-sm-0 pt-4"
        />
      </v-col>

      <v-col cols="12" sm="4" v-if="switchPorcentajes">
          <v-switch v-model="porcentajes" :class="switchClass" color="blue-grey darken-2" inset label="Porcentajes"/>
      </v-col>

      <v-col cols="12" sm="4" v-if="procesar">
        <div class="pt-sm-10" >
          <filtro-procesar @changed="changed"/>
        </div>
      </v-col>

      <v-col
          v-if="acciones"
          cols="12"
          sm="4"
          class="hidden-xs-only">
        <acciones class="mr-5 mb-4 pt-sm-11 d-flex justify-end"/>
      </v-col>
    </v-row>

    <v-data-table
        :headers="headersComputed"
        :items="data"
        :search="search"
        :loading="loading"
        class="elevation-6"
        disable-items-per-page
        disable-sort
        :hide-default-footer="footer"
        mobile-breakpoint="300"
        :items-per-page="itemsPerPage"
    >
      <template v-slot:item.gobernador="{ item }">
        <v-icon v-if="!hasValue(item, 'gobernador')">mdi-square-medium</v-icon>
        {{ renderValue(item, 'gobernador') }}
      </template>

      <template v-if="porcentajes" v-slot:item.gobernador_porcentaje="{ item }">
        <v-icon v-if="isVotoInvalido(item, 'gobernador')">mdi-square-medium</v-icon>
        <div v-else>{{ calculatePercentaje(item, 'gobernador') }}</div>
      </template>

      <template v-slot:item.senadores="{ item }">
        <v-icon v-if="!hasValue(item, 'senadores')">mdi-square-medium</v-icon>
        {{ renderValue(item, 'senadores') }}
      </template>

      <template v-if="porcentajes" v-slot:item.senadores_porcentaje="{ item }">
        <v-icon v-if="isVotoInvalido(item, 'senadores')">mdi-square-medium</v-icon>
        <div v-else>{{ calculatePercentaje(item, 'senadores') }}</div>
      </template>

      <template v-slot:item.diputados="{ item }">
        <v-icon v-if="!hasValue(item, 'diputados')">mdi-square-medium</v-icon>
        {{ renderValue(item, 'diputados') }}
      </template>

      <template v-if="porcentajes" v-slot:item.diputados_porcentaje="{ item }">
        <v-icon v-if="isVotoInvalido(item, 'diputados')">mdi-square-medium</v-icon>
        <div v-else>{{ calculatePercentaje(item, 'diputados') }}</div>
      </template>

      <template v-slot:item.intendente="{ item }">
        <v-icon v-if="!hasValue(item, 'intendente')">mdi-square-medium</v-icon>
        {{ renderValue(item, 'intendente') }}
      </template>

      <template v-if="porcentajes" v-slot:item.intendente_porcentaje="{ item }">
        <v-icon v-if="isVotoInvalido(item, 'intendente')">mdi-square-medium</v-icon>
        <div v-else>{{ calculatePercentaje(item, 'intendente') }}</div>
      </template>

      <template v-slot:item.concejales="{ item }">
        <v-icon v-if="!hasValue(item, 'concejales')">mdi-square-medium</v-icon>
        {{ renderValue(item, 'concejales') }}
      </template>

      <template v-if="porcentajes" v-slot:item.concejales_porcentaje="{ item }">
        <v-icon v-if="isVotoInvalido(item, 'concejales')">mdi-square-medium</v-icon>
        <div v-else>{{ calculatePercentaje(item, 'concejales') }}</div>
      </template>

      <template v-slot:item.convencionales="{ item }">
        <v-icon v-if="!hasValue(item, 'convencionales')">mdi-square-medium</v-icon>
        {{ renderValue(item, 'convencionales') }}
      </template>

      <template v-if="porcentajes" v-slot:item.convencionales_porcentaje="{ item }">
        <v-icon v-if="isVotoInvalido(item, 'convencionales')">mdi-square-medium</v-icon>
        <div v-else>{{ calculatePercentaje(item, 'convencionales') }}</div>
      </template>

      <template v-slot:item.defensor_del_pueblo="{ item }">
        {{ renderValue(item, 'defensor_del_pueblo') }}
      </template>

      <template v-if="porcentajes" v-slot:item.defensor_del_pueblo_porcentaje="{ item }">
        <v-icon v-if="isVotoInvalido(item, 'defensor_del_pueblo')">mdi-square-medium</v-icon>
        <div v-else>{{ calculatePercentaje(item, 'defensor_del_pueblo') }}</div>
      </template>

      <template v-if="porcentajes" v-slot:item.votos_porcentaje="{ item }">
        <div>{{ calculatePercentaje(item, 'votos') }}</div>
      </template>

      <template v-slot:item.estado="{ item }">
        <v-icon
            dense
            :color="getColor(item.estado)"
            v-text="getIcono(item.estado)"
        >
        </v-icon>
      </template>

      <template v-slot:item.telegrama="{ item }">
        <v-icon @click="openTelegrama(item)">
          mdi-file-document
        </v-icon>
      </template>

      <template v-slot:item.ver="{ item }">
        <v-icon @click="openModal(item)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
    <ModalDetalleMesas :show="showModal" :item="modalData" @close="closeModal"/>
    <v-dialog v-model="showTelegrama" width="600">
      <a v-if="telegramaPath" target="_blank" :href="telegramaPath" :download="telegrama.id"><v-img :src="telegramaPath" contain/></a>
      <v-card v-else>
        <h3 class="text text-center my-auto py-12 blue-grey--text text--darken-3 font-weight-bold">Este telegrama aún no
          se encuentra disponible.</h3>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FiltroProcesar from "../components/FiltroProcesar"
import Acciones from "./Acciones";
import ModalDetalleMesas from "./ModalDetalleMesas";

export default {
  components: {
    FiltroProcesar,
    Acciones,
    ModalDetalleMesas
  },

  props: {
    headers: Array,
    data: Array,
    switchPorcentajes: {type: Boolean, default: false},
    porcentajes: {type: Boolean, default: false},
    filtro: {type: Boolean, default: false},
    procesar: {type: Boolean, default: false},
    acciones: {type: Boolean, default: false},
    telegrama: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    itemsPerPage: {type: Number, default: -1},
    footer: {type: Boolean, default: false},
    switchClass: {type: String, default: 'pt-sm-9 pl-sm-4'},
    validos: null,
  },
  data() {
    return {
      search: '',
      showModal: false,
      modalData: {},
      showTelegrama: false,
      telegramaPath: null,
    }
  },

  computed: {
    headersComputed() {
      if (!this.porcentajes) return this.headers;
      let headers = [];
      this.headers.forEach((header) => {
        headers.push(header)
        if (header.text !== '#' && header.value !== 'partido' && header.value !== 'alianza') {
          headers.push({
            class: header.class,
            text: '%',
            value: header.value + '_porcentaje'
          })
        }
      })
      return headers;
    }
  },

  methods: {
    calculatePercentaje(item, key) {
      let conInvalidos = item.partido !== 'Votos en Blanco'
                          && item.partido !== 'Votos Nulos'
                          && item.partido !== 'Votos Recurridos'
                          && item.partido !== 'Votos de Identidad Impugnada';
      let sum = this.sumField(key, conInvalidos);
      let porcentaje = Math.round(item[key] / sum * 10000) / 100;
      if (isNaN(porcentaje)) return 0;
      return porcentaje;
    },

    sumField(key, conInvalidos = false) {
      // sum data in give key (property)
      // return this.data.reduce((a, b) => a + (b[key] || 0), 0)
      if (this.validos) {
        if (key === 'intendente') return this.validos[key + 's_validos']
        return this.validos[key + '_validos']
      }
      return this.data.filter((partido) => {
        if (!conInvalidos) return partido;
        return partido.partido !== 'Votos en Blanco'
                && partido.partido !== 'Votos Nulos'
                && partido.partido !== 'Votos Recurridos'
                && partido.partido !== 'Votos de Identidad Impugnada'
      }).reduce((a, b) => a + (b[key] || 0), 0)
    },

    hasValue(item, value) {
      return !(item[value] < 0 || item[value] === null);
    },

    isVotoInvalido(item, value) {
      if (value === 'gobernador' && item[value] < 0) return true;
      if (value === 'gobernador'|| item[value] >= 0) return false;
      return item.partido === 'Votos de Identidad Impugnada' || item[value] < 0;
    },

    renderValue(item, value) {
      return this.hasValue(item, value) ? item[value] : null;
    },
    getIcono(estado) {
      if (estado) return 'mdi-check-bold'
      else return 'mdi-close-thick'
    },

    getColor(estado) {
      if (estado) return 'green darken-2'
      else return 'red darken-2'
    },

    changed(value) {
      this.$emit("changed", value)
    },

    closeModal() {
      this.showModal = false;
    },

    openModal(item) {
      this.modalData = item;
      this.showModal = !this.showModal;
    },

    openTelegrama(item) {
      this.showTelegrama = true;
      this.telegramaPath = item.telegrama;
    }
  },

}
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: "MyriadProCond", serif !important;
  font-size: 18px !important;
}

.text {
  font-family: MyriadProCond, serif !important;
  font-size: 28px !important;
}
</style>