<template>
  <div>
      <v-select
          color="blue-grey"
          v-model="seleccionado"
          :items="items"
          label="Ver mesas"
          dense
          outlined
          item-color="blue-grey darken-1"
          @change="changed"
      />
  </div>
</template>

<script>
export default {
  data: () => ({
    seleccionado: null,
    items: [
      {
        "text": "Todas",
        "value": null
      },
      {
        "text": "Escrutadas",
        "value": 1
      },
      {
        "text": "Por Escrutar",
        "value": 0
      }
    ],
  }),
  watch: {
    $route() {
      this.seleccionado = null;
    }
  },
  methods: {
    changed() {
      this.$emit("changed", this.seleccionado)
    }
  }
}
</script>